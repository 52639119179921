import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		isShowLoading: false, // 全局 loading
		// 左侧菜单栏数据
		menuItems: [{
				name: 'home', // 要跳转的路由名称 不是路径
				size: 18, // icon大小
				type: 'md-home', // icon类型
				text: '主页', // 文本内容
			},
			{
				text: '产品-木业',
				type: 'ios-paper',
				children: [{
						type: 'ios-grid',
						name: 'product',
						text: '产品列表'
					},
					{
						type: 'ios-grid',
						name: 'addproduct',
						text: '新增产品'
					},
					{
						type: 'ios-grid',
						name: 'updataproduct',
						text: '编辑'
					},
					{
						type: 'ios-grid',
						name: 'type',
						text: '产品分类列表'
					},
					{
						type: 'ios-grid',
						name: 'addtype',
						text: '新增产品分类'
					},
					{
						type: 'ios-grid',
						name: 'updatetype',
						text: '编辑'
					}
				]
			},
			{
				text: '产品-地板',
				type: 'ios-paper',
				children: [{
						type: 'ios-grid',
						name: 'productflooring',
						text: '产品列表'
					},
					{
						type: 'ios-grid',
						name: 'addproductflooring',
						text: '新增产品'
					},
					{
						type: 'ios-grid',
						name: 'updataproductflooring',
						text: '编辑'
					},
					{
						type: 'ios-grid',
						name: 'typeflooring',
						text: '产品分类列表'
					},
					{
						type: 'ios-grid',
						name: 'addtypeflooring',
						text: '新增产品分类'
					},
					{
						type: 'ios-grid',
						name: 'updatetypeflooring',
						text: '编辑'
					}
				]
			},
			{
				text: '授权',
				type: 'ios-paper',
				children: [{
						type: 'ios-grid',
						name: 'empower',
						text: '授权列表'
					},
					{
						type: 'ios-grid',
						name: 'addempower',
						text: '新增授权'
					},
					{
						type: 'ios-grid',
						name: 'updateempower',
						text: '编辑'
					}
				]
			},
			{
				text: '新闻',
				type: 'ios-paper',
				children: [{
						type: 'ios-grid',
						name: 'news',
						text: '新闻列表'
					},
					{
						type: 'ios-grid',
						name: 'addnews',
						text: '新增新闻'
					},
					{
						type: 'ios-grid',
						name: 'updatenews',
						text: '编辑'
					}
				]
			},{
				text: '动态内容',
				type: 'ios-paper',
				children: [{
						type: 'ios-grid',
						name: 'banner',
						text: '轮播图'
					},{
						type: 'ios-grid',
						name: 'addbanner',
						text: '新增轮播图'
					},{
						type: 'ios-grid',
						name:'updatabanner',
						text: '编辑'
					},{
						type: 'ios-grid',
						name: 'number',
						text: '关于克诺斯邦-数据'
					},
					{
						type: 'ios-grid',
						name: 'indexflooring',
						text: '介绍和简介'
					},
					{
						type: 'ios-grid',
						name: 'indexupdataflooring',
						text: '编辑'
					},
					{
						type: 'ios-grid',
						name: 'future',
						text: '首页- 未来&精选项目&精选花色'
					},
					{
						type: 'ios-grid',
						name: 'futureupdata',
						text: '编辑'
					}
				]
			}
		],
	},
	mutations: {
		setMenus(state, items) {
			state.menuItems = [...items]
		},
		setLoading(state, isShowLoading) {
			state.isShowLoading = isShowLoading
		},
	},
})

export default store